import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Grid, Typography, Button} from '@mui/material'
import PhoneIcon from './phoneIcon'
import TextIcon from './textIcon'
import EmailIcon from './emailIcon'
import useIsMobile from '../../hooks/useIsMobile'
 
const ContactSection = () => {
    const theme = useTheme()
    const { isMobile } = useIsMobile()
    const RelativeContainer = styled('div')({
        position:'relative',
        backgroundColor:theme.palette.gradients.lavenderBlush,
        overflow:'hidden',
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'center',
        paddingBottom:'100px',
        [theme.breakpoints.up('md')]:{
          height:'100%'
        },
        background:`linear-gradient(45deg, ${theme.palette.gradients.dodgerBlue} -50%, transparent 20%, transparent 80%, ${theme.palette.gradients.frostbite} 150%)`,
        ["::before"]: {
          content: '""',
          background:`linear-gradient(25deg, transparent 5%, ${theme.palette.gradients.zaffre} 48%, ${theme.palette.gradients.zaffre} 52%, transparent 95%)`,
          position: "absolute",
          top:0,
          right:0,
          left:0,
          bottom:0,
        },
        ["::after"]: {
          content: '""',
          background:`linear-gradient(-45deg, ${theme.palette.gradients.lightGreen} -50%, transparent 20%, transparent 80%, ${theme.palette.gradients.lavenderBlush} 150%)`,
          position: "absolute",
          top:0,
          right:0,
          left:0,
          bottom:0,
        },

    })
    const TitleContainer = styled(Grid)({
        margin:'25px 0',
        width:'100%',
        textAlign:"center",
        color:theme.palette.gradients.lavenderBlush,
        zIndex:15,
        '& h5':{
            fontSize:'19px',
            fontWeight:700,
            marginLeft:'10px',
            fontFamily:'Rubik'
        },
        '& h6':{
          fontSize:'16px',
          fontFamily:'Rubik',
          color:theme.palette.gradients.lavenderBlush
          
        },
        '& p':{
            fontSize:'18px',
            color:'black',
            fontWeight:800,
            padding:'0 10px',
            fontFamily:'Holtwood One SC',
            backgroundImage:`linear-gradient(0deg,${theme.palette.gradients.lightGreen} 37%,${theme.palette.gradients.lavenderBlush} 37%,${theme.palette.gradients.lavenderBlush} 100%)`,
            backgroundClip:'text',
            '-webkit-background-clip':'text',
            color:'transparent',
            [theme.breakpoints.up('md')]:{
              fontSize:'25px'
            }
        }
    })
    const Bouton = styled(Button)({
        width:'300px',
        height:'auto',
        display:'flex',
        justifyContent:'initial',
        flexDirection:'column',
        '& h6':{
          fontSize:'16px',
          fontFamily:'Rubik',
          color:theme.palette.gradients.lavenderBlush
          
        },
        [theme.breakpoints.up('md')]:{
          width:'auto',
          flexDirection:'column',
          '& h6':{
            fontSize:'20px'
          },
        },
        '& p':{
          color:theme.palette.gradients.lightGreen
        }
    })
    const A = styled('a')({
      textDecoration:'none',
      display:'flex',
      flexForm:'row',
      justifyContent:'center',
      [theme.breakpoints.up('md')]:{
        width:'16.67%'
      }
    })
      const width = 
      window?.innerWidth > theme.breakpoints.values.sm //larger than small
      ?
      window?.innerWidth < theme.breakpoints.values.md //smaller than medium
      ? 
      '300px' //small
      :
      window?.innerWidth < theme.breakpoints.values.lg //smaller than large
      ?
      '150px' //medium
      :
      window?.innerWidth < theme.breakpoints.values.xl //smaller than extra large
      ?
      '200px' // large
      :
      '200px' // xlarge
      :
      '75px' // xsmall
  return (
    <RelativeContainer>
        <TitleContainer>
            <Typography variant="body2" align="center">
                {
                  isMobile ? 'Touch the icons for the applicable app to open!' : 'Thank you for your interest, please us one of the below options to contact us!'
                }
            </Typography>   
        </TitleContainer>
        <A href='tel:18074645268'>
        <Bouton>
            <Typography variant="h6">
                CALL
            </Typography>
            <PhoneIcon width={width}/>
            <Typography variant="h6">
                {
                  `(807)464-5268`
                }
            </Typography>
        </Bouton>
        </A>
        <A href='sms:8074645268'>
        <Bouton>
            <Typography variant="h6">
                TEXT
            </Typography>
            <TextIcon width={width}/>
            <Typography variant="h6">
                {
                  `(807)464-5268`
                }
            </Typography>
        </Bouton>
        </A>
        <A href='mailTo:info@lotwebdesign.com'>
        <Bouton>
            <Typography variant="h6">
                EMAIL
            </Typography>
            <EmailIcon width={width}/>
            <Typography variant="h6">
                {
                  `info@lotwebdesign.com`
                }
            </Typography>
        </Bouton>
        </A>
    </RelativeContainer>
  )
}

export default ContactSection